import React from 'react';





const Resume = () => {

    return (
        <div className="App">
            {/*<header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>*/}

            {/* <h1>Harun Dastekin</h1>*/}

            {/* <head>
    <title>Harun Dastekin</title>
    <nav className="topnav">
      <ul>
        <li className="topnavitem"><a href=" ">my
          interests</a></li>
        <li className="topnavitem"><a href=" ">my
          personal projects</a></li>
        <li className="topnavitem"><a href=" ">my
          hobbies</a></li>

        <li className="topnavitem">

        </li>
      </ul>
    </nav>
  </head>*/}


            {/*<body>*/}


            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <div>
                    <h1>Harun Dastekin</h1>
                    <h2><a href='https://github.com/hdi10'>@hdi10</a></h2>
                </div>
            </section>


            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////////////         PRAXISERFAHRUNG                     /////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h2>BERUFSERFAHRUNG</h2>

                <div style={{
                    fontFamily: 'Arial, sans-serif',
                    padding: '20px',
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    maxWidth: '600px',
                    margin: '0 auto',
                    backgroundColor: '#f9f9f9'
                }}>


                    <div className="resume-container">
                        <h2 style={{textAlign: 'center', color: '#333'}}>Toll Collect GmbH</h2>
                        <hr style={{border: '0', height: '1px', background: '#ccc', margin: '10px 0'}}/>
                        <p style={{textAlign: 'center', fontSize: '16px', color: '#555'}}><strong>Werkstudent</strong>
                        </p>
                        <p style={{textAlign: 'center', fontSize: '14px', color: '#777'}}>Softwareentwickler
                            Testautomatisierung</p>
                        <p style={{textAlign: 'center', fontSize: '14px', color: '#777'}}>Dezember 2021 – Dezember
                            2023</p>
                        <hr style={{border: '0', height: '1px', background: '#ccc', margin: '10px 0'}}/>
                        <h3 style={{color: '#444'}}>Schwerpunkte:</h3>
                        <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                            <li style={{marginBottom: '8px'}}><strong>Grafana Dashboard Visualisierung:</strong> Analyse
                                von
                                Fahrzeuggeräte-Logs
                            </li>
                            <li style={{marginBottom: '8px'}}><strong>Kameraüberwachung:</strong> Integration und
                                Monitoring
                            </li>
                            <li style={{marginBottom: '8px'}}><strong>Tickettracking:</strong> Jira und Bugzilla</li>
                            <li style={{marginBottom: '8px'}}><strong>Containerization:</strong> Docker-basierte
                                Testumgebungen
                            </li>
                            <li style={{marginBottom: '8px'}}><strong>Testautomatisierung:</strong> Tools wie HP ALM und
                                Microfocus UFT
                            </li>
                        </ul>

                    </div>

                </div>


            </section>

            <section className="hidden">
                <div style={{
                    fontFamily: 'Arial, sans-serif',
                    padding: '20px',
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    maxWidth: '600px',
                    margin: '0 auto',
                    backgroundColor: '#f9f9f9'
                }}>


                    <div className="resume-container">
                        <h2 style={{textAlign: 'center', color: '#333', marginTop: '20px'}}>Entraserv GmbH</h2>
                        <hr style={{border: '0', height: '1px', background: '#ccc', margin: '10px 0'}}/>
                        <p style={{textAlign: 'center', fontSize: '16px', color: '#555'}}><strong>Werkstudent</strong>
                        </p>
                        <p style={{textAlign: 'center', fontSize: '14px', color: '#777'}}>IT-Berater und
                            Softwareentwickler</p>
                        <p style={{textAlign: 'center', fontSize: '14px', color: '#777'}}>Mai 2021 – Dezember 2021</p>
                        <hr style={{border: '0', height: '1px', background: '#ccc', margin: '10px 0'}}/>

                        <h3 style={{color: '#444'}}>Schwerpunkte:</h3>
                        <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                            <li style={{marginBottom: '8px'}}><strong>IT-Beratung:</strong> Unterstützung bei Apple iPad
                                Kassensystemen
                            </li>
                            <li style={{marginBottom: '8px'}}><strong>Android App Entwicklung:</strong> Lösungen für
                                Fahrzeugüberführung
                            </li>
                            <li style={{marginBottom: '8px'}}><strong>Bildbearbeitung:</strong> Nutzung von GIMP</li>
                            <li style={{marginBottom: '8px'}}><strong>Videobearbeitung:</strong> Bearbeitung mit
                                Biteable
                            </li>
                            <li style={{marginBottom: '8px'}}><strong>Vertrieb:</strong> Unterstützung und Beratung</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">

                <div style={{
                    fontFamily: 'Arial, sans-serif',
                    padding: '20px',
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    maxWidth: '600px',
                    margin: '0 auto',
                    backgroundColor: '#f9f9f9'
                }}>

                    <section className="hidden">
                        <div className="resume-container">
                            <h2 style={{textAlign: 'center', color: '#333', marginTop: '20px'}}>Stromnetz Berlin
                                GmbH</h2>
                            <hr className="resume-divider"/>
                            <p style={{textAlign: 'center', fontSize: '16px', color: '#555'}}>
                                <strong>Werkstudent</strong></p>
                            <p style={{textAlign: 'center', fontSize: '14px', color: '#777'}}>Trassenmanagement und
                                Leitungsauskunft</p>
                            <p style={{textAlign: 'center', fontSize: '14px', color: '#777'}}>April 2019 – April
                                2021</p>
                            <hr className="resume-divider"/>
                            <h3 style={{color: '#444'}}>Schwerpunkte:</h3>
                            <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                                <li style={{marginBottom: '8px'}}><strong>Leitungsanfragen:</strong> Bearbeitung und
                                    Beantwortung
                                </li>
                                <li style={{marginBottom: '8px'}}><strong>SharePoint:</strong> Unterstützung der Präsenz
                                </li>
                            </ul>
                        </div>
                    </section>

        </div>
</section>


    {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
    }

    {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
    }
    {/*//////////                         BILDUNGSWEG                            ////////////////////////////////////////////////*/
    }
    {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
    }
    <section className="hidden">
        <h1>BILDUNGSWEG</h1>
        <div>
            <screenLeft>
                <strong> Seit 09.2024 ISTQB Certified Tester Foundation Level(CTFL)<br/></strong>
            </screenLeft>
        </div>
        <div>
            <center>
                <hr></hr>
                <strong>04/2019 - 11/2024<br/></strong>
                Bachelor-Studium der<br/> <b>Angewandte Informatik (B.Sc.)</b><br/>
                        an der<br/> <b>Hochschule für Technik und Wirtschaft (HTW)</b> <br/>Berlin
                    </center>
                </div>

            </section>


            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////           AUßERUNIVERSITÄRES ENGAGEMENT</h1>                        /////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>EHRENAMT</h1>

                <div>
                    <center><strong>12/2018 - 02/2019<br/></strong>
                        <strong>12/2016 - 01/2017<br/></strong>
                        <strong>11/2015 - 03/2016<br/></strong>
                        <hr></hr>
                        <b>Ehrenamtliche Aushilfe</b><br/>
                        für eine Gemeinnützige Einrichtung<br/>
                        der <b>Gebewo</b><br/>in Berlin
                        <br/>während der Wintermonate<br/>

                    </center>
                    <br/>
                    <ul>
                        <li>Organisation und Durchführung der Arbeit am Abend</li>
                        <li>Führen von thematischen Einzel- und Gruppengesprächen</li>
                        <li>Informationen zu möglichen Beratungs- und Hilfsangeboten</li>
                        <li>Lösen von Konfliktsituationen</li>
                        <li>Statistik und Dokumentation</li>
                    </ul>
                </div>

            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////////           KENNTNISSE & FÄHIGKEITEN              /////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>KENNTNISSE & FÄHIGKEITEN</h1>
                <h2>Sprachen</h2>
                <ul>
                    <li>Deutsch (fließend)</li>
                    <li>Türkisch (fließend)</li>
                    <li>Englisch (fortgeschritten)</li>
                </ul>

            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">

                <h2>IT-Kenntnisse</h2>

                <div>
                    <div>
                        <h3>Microsoft Office</h3>
                        <ul>
                            <li>MS Word (sehr gut)</li>
                            <li>MS Excel (sehr gut)</li>
                            <li>MS PowerPoint (sehr gut)</li>
                            <li>One Note (sehr gut)</li>
                        </ul>
                    </div>

                    <div>
                        <h3>Apple Iwork</h3>
                        <ul>
                            <li>Pages (gut)</li>
                            <li>Numbers (gut)</li>
                            <li>Keynote (gut)</li>
                        </ul>
                    </div>
                </div>

                <h2>Programme und Sprachen</h2>
                <h3>IT-Software oder Toolchain</h3>
                <div>
                    Node RED, Grafana, Influx, TelegramBot
                    <hr></hr>
                    Android Studio, IntelliJ, Eclipse, VSCode
                    <hr></hr>
                    Latex, Git,
                    Arduino IDE, GIMP, Spring,
                    <hr></hr>
                    Docker, Shell-Scripting,
                    Batch-Scripting, JUnit,
                    <hr></hr>
                    Android Espresso Testing, Spring,
                    REST, Zephyr,
                    <hr></hr>
                    Powershell, VBA, Jira, Git, Maven,
                    <hr></hr>
                    Gradle, Elasticsearch, Heroku, GitLab CD/CI
                </div>
                <h3>Programmiersprachen</h3>
                <div>
                    Java, C, C++,
                    <hr></hr>
                    Javascript, Typescript
                    Android,
                    <hr></hr>
                    MicroPython,
                    PostgreSQL, Influx
                </div>

            </section>

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////             QUALIFIKATIONEN                  ////////////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>QUALIFIKATIONEN</h1>
                <div>
                    Zertifizierter Mediator (Konfliktlotse)
                    <hr></hr>
                    Hochschul-Fremdsprachenzertifikat in Englisch (UNIcert II -Englisch)
                    <hr></hr>
                    ISTQB Certified Tester Foundation Level(CTFL) - Zertifikatsnummer: 24-CTFL 4-251342-01
                </div>

            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////             My Personal Projects                  ///////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <div>
                    <h2>Zu meinen Interessen gehören</h2>
                    <p>
                        {/*<b Stromnetz, VMWare, Docker, Keycloak, NodeJS, Oracle MySQL, PostgreSQL, Amazon Web Services(AWS),Kubernetes, Angular, Spring Boot, TypeScript, Cisco */}
                        MicroPython , ESP32, Software, Hardware, Mikroelektronik
                        <hr></hr>
                        Java, Android, Grafana, Testing,
                        <hr></hr>
                        Git und GitLab CD/CI , PostgreSQL als DBMS, Spring Boot als Microservice Ecosystem
                    </p>
                </div>


            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////             Hobbies                               ///////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>Hobbies</h1>
                <p>Meine Hobbies sind Bodenturnen, Kampfsport und Lesen.</p>
            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*</body>*/}


        </div>

    )
};

export default Resume;
